import {PricingOption as PricingOptionType} from '@wix/ambassador-wix-events-web/types'
import React from 'react'
import {useCurrencyFormatter} from '../../../../../../../../commons/hooks/currency'
import {getLocale} from '../../../../../../../../commons/selectors/environment'
import {usePageStateSelector} from '../../../../../hooks/state'
import {Fees} from '../../fields/fees'
import {Price} from '../../fields/price'

export const PricingOptionPrice = ({
  ticket,
  pricingOption,
}: {
  ticket: wix.events.ticketing.TicketDefinition
  pricingOption: PricingOptionType
}) => {
  const locale = usePageStateSelector(getLocale)
  const {getFormattedMoney} = useCurrencyFormatter(locale)
  const {name, id} = pricingOption

  const price = getFormattedMoney(pricingOption.price)

  return (
    <>
      <Price label={name} id={id} price={price} extraAriaLabeledBy={`label-price-${ticket.id}`} />
      <Fees ticket={ticket} overridePrice={pricingOption.price.amount} />
    </>
  )
}
